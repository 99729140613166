import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyCarouselReviews = lazy(
  () => import("./carousel-reviews-BAYmr9_D.js").then((module) => ({
    default: module.CarouselReviews
  }))
);
function CarouselReviews({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyCarouselReviews, { ...props });
}
const useCarouselReviewsContent = () => {
  return useMemo(
    () => ({
      carouselReviews: {
        component: CarouselReviews
      }
    }),
    []
  );
};
export {
  LazyCarouselReviews as CarouselReviews,
  useCarouselReviewsContent
};
